import React from 'react'
import { SectionFeaturedArticles } from '../../../types/sections'
import { Box, Button, Flex, Heading } from '../../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { PostPreview } from '../../blog/commons'
import { FeaturedArticlesCol, FeaturedArticlesRow } from './FeaturedArticles.styled'
import { SECTION_MARGIN } from '../../../utils/constants'

function FeaturedArticles(props: SectionFeaturedArticles): JSX.Element {
  const { id, sectionId, headline, button, articles, bottomButton } = props

  return (
    <Box as={'section'} id={sectionId ?? id} my={SECTION_MARGIN}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Flex
              justifyContent={'space-between'}
              width={'100%'}
              alignItems={['flex-start', 'flex-start', 'flex-end']}
              gap={16}
              flexDirection={['column', 'row', 'row']}
            >
              <Heading as='h2' type='heading1'>
                {headline}
              </Heading>
              {button && button[0] && (
                <Button buttonType={button[0].buttonType} link={button[0].link} label={button[0].label} />
              )}
            </Flex>
          </Col>
        </Row>
        <Box mt={[6, 7, 8]}>
          <FeaturedArticlesRow className={'noscrollBar'}>
            {articles.map((article) => (
              <FeaturedArticlesCol key={article.id} xs={10} sm={5} md={4}>
                <Box mb={[6, 7, 0]}>
                  <PostPreview data={article} />
                </Box>
              </FeaturedArticlesCol>
            ))}
          </FeaturedArticlesRow>
        </Box>

        {bottomButton && bottomButton[0] && (
          <Flex mt={[6, 7, 8]} justifyContent={'center'} alignItems={'center'} gap={4} flexWrap={'wrap'}>
            <Button {...bottomButton[0]} />
          </Flex>
        )}
      </Grid>
    </Box>
  )
}

export default FeaturedArticles
